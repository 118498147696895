import React, { useContext, useEffect, useState } from "react";
import HorizontalLinearStepper from "../../common/Stepper/HorizontalLinearStepper";
import useMediaQuery from "@mui/material/useMediaQuery";
import VerticalLinearStepper from "../../common/Stepper/VerticalLinearStepper";
import useTheme from "@mui/material/styles/useTheme";
import LoginStep from "./LoginStep";
import SelectAddressStep from "./SelectAddressStep";
import OrderSummary from "./OrderSummary";
import UserProfileContext from "../../../context/userProfile/UserProfileContext";
import { Grid } from "@mui/material";
import CheckoutButton from "../../common/CheckoutButton/CheckoutButton";
import CustomButton from "../../common/Button/CustomButton";
import UserDataAPI from "../../../api/firebase/UserDataAPI";
import OrdersAPI from "../../../api/firebase/OrdersAPI";
import { useNavigate } from "react-router-dom";
import AlertDialogSlide from "../../common/Dialog/AlertDialogSlide";
import CouponsAPI from "../../../api/firebase/CouponsAPI";
const PlaceOrder = ({ handleClose }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const couponsAPI = new CouponsAPI();
  const [user, setUser] = useState(null);
  const userProfileContext = useContext(UserProfileContext);
  const mobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [activeStep, setActiveStep] = useState(0);
  const [loginCompleted, setLoginCompleted] = useState(false);
  const [addressCompleted, setAddressCompleted] = useState(false);
  const [loading, setLoading] = useState(true);
  const [cartItems, setCartItems] = useState([]);
  const [selAddress, setSelAddress] = useState(null);
  const [currentOrder, setCurrentOrder] = useState(null);
  const [shippingAmount, setShippingAmount] = useState(0);
  const [couponDiscount, setCouponDiscount] = useState(0);
  const [codCharges, setCodCharges] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [finalAmount, setFinalAmount] = useState(0);
  const userDataApi = new UserDataAPI();
  const ordersAPI = new OrdersAPI();
  useEffect(() => {
    setLoading(false);
    if (userProfileContext.state !== null) {
      // console.log("user:", userProfileContext.state);
      setCodCharges(
        userProfileContext.state.codCharges
          ? userProfileContext.state.codCharges
          : 0
      );
      setUser({ ...userProfileContext.state });
      setLoginCompleted(true);
    } else {
      setUser(null);
      setLoginCompleted(false);
    }
    if (
      userProfileContext.state !== null &&
      userProfileContext.state.cartItems !== undefined &&
      userProfileContext.state.cartItems !== null
    ) {
      setCartItems(userProfileContext.state.cartItems);
      setShippingAmount(
        userProfileContext.state.shippingCost
          ? userProfileContext.state.shippingCost
          : 0
      );
      const couponDis = userProfileContext.state.couponApplied
        ? userProfileContext.state.couponApplied.amount
        : 0;
      setCouponDiscount(couponDis);
    } else {
      setCartItems([]);
    }
  }, [userProfileContext.state]);

  useEffect(() => {
    //console.log("cartItems:", cartItems);
    // console.log(
    //   "shippingAmount:",
    //   shippingAmount,
    //   "codCharges:",
    //   codCharges,
    //   "couponDiscount:",
    //   couponDiscount
    // );
    const fAmount = cartItems.reduce(
      (a, v) => (a = a + v.listPrice * v.quantity),
      0
    );
    setFinalAmount(fAmount + shippingAmount + codCharges - couponDiscount);
  }, [shippingAmount, codCharges, couponDiscount]);

  const handleStep = (index) => {
    setActiveStep(index);
  };
  const handleCreateOrder = (props) => {
    //console.log("handleCreateOrder:", props);
    const { success, order } = props;
    if (!success) return;
    const orderData = {
      id: order.id,
      amount: order.amount / 100,
      status: order.status,
      shippingCost: shippingAmount,
      couponApplied: userProfileContext.state.couponApplied
        ? userProfileContext.state.couponApplied
        : null,
      user: {
        id: user.id,
        name: user.displayName,
        email: user.email,
      },
      items: [...cartItems],
      address: selAddress,
      createdAt: new Date().toString(),
    };
    // Add current order in database

    const dataForOrdersTable = {
      id: orderData.id,
      payload: orderData,
    };
    ordersAPI.setOrder(dataForOrdersTable).then((resOrder) => {
      // console.log("RES ORDER:", resOrder);
      const prevOrders = user.orders !== undefined ? user.orders : [];
      const dataForUsersTable = {
        id: user.id,
        payload: {
          orders: [{ ...orderData }, ...prevOrders],
        },
      };
      // console.log("dataForUsersTable:", dataForUsersTable);
      userDataApi.addUserData(dataForUsersTable).then((res) => {
        // console.log("RES from user data update:", res);
        if (res.success) {
          userProfileContext.setState((prev) => ({
            ...prev,
            cartItems: [],
            couponApplied: null,
            codCharges: 0,
            shippingCost: 0,
            orders: dataForUsersTable.payload.orders,
          }));
          // navigate to order placed page
          //navigate("/orderPlaced");
        }
      });
    });
    //
    setCurrentOrder({ ...orderData });
  };
  const sendMail = async (orderID = "") => {
    const url = "https://payments.inproveda.com/public/api/order_placed";
    const customerName = user.displayName;
    const customerEmail = user.email;
    orderID = currentOrder !== null ? currentOrder.id : orderID;
    const orderId = orderID;
    const tableHeader =
      "<table style='width: 100%;'>" +
      "<tr>" +
      "<th style='text-align:left'></th>" +
      "<th style='text-align:left'>Product</th>" +
      "<th style='text-align:left'>List Price</th>" +
      "<th style='text-align:left'>Total</th>" +
      "</tr>";
    let tableBody = "";
    cartItems.map((item, index) => {
      tableBody +=
        "<tr><td style='vertical-align: top;'>" +
        (index + 1) +
        ".</td><td style='padding-right:16px'>" +
        item.title +
        "  x " +
        item.quantity +
        " </td> <td>₹" +
        item.listPrice +
        "</td><td>₹" +
        item.listPrice * item.quantity +
        "</td></tr>";
    });
    // Calculate final amount based on ppu and quantity of OrderItemsArr
    const totalItemsAmount = cartItems.reduce(function (
      accumulator,
      currentValue
    ) {
      return accumulator + currentValue.listPrice * currentValue.quantity;
    },
    0);
    const tableShippingAmount =
      "<tr><td></td><td></td><td>Shipping</td><td>" +
      (shippingAmount === 0 ? "Free" : `₹${shippingAmount}`) +
      "</td></tr>";
    let tableCODCharges = "";
    if (codCharges > 0) {
      tableCODCharges =
        `<tr><td></td><td></td><td>COD Charges</td><td>+ ₹` +
        codCharges +
        "</td></tr>";
    }
    let tableCouponDiscount = "";
    if (couponDiscount > 0) {
      tableCouponDiscount =
        `<tr><td></td><td></td><td>Coupon (${userProfileContext.state.couponApplied.couponCode})</td><td>- ₹` +
        couponDiscount +
        "</td></tr>";
    }

    const tableFinalAmount =
      "<tr><td></td><td></td><td>Final Amount</td><td>₹" +
      (totalItemsAmount + codCharges + shippingAmount - couponDiscount) +
      "</td></tr>";
    const orderSummaryTable =
      tableHeader +
      tableBody +
      tableShippingAmount +
      tableCODCharges +
      tableCouponDiscount +
      tableFinalAmount +
      "</table>";
    const nameTrimmed = selAddress.name.replace(/[^a-zA-Z0-9,()-+ ]/g, "");
    const addressTrimmed = selAddress.address.replace(
      /[^a-zA-Z0-9,()-+ ]/g,
      ""
    );
    const cityTrimmed = selAddress.city.replace(/[^a-zA-Z0-9,()-+ ]/g, "");
    const pincodeTrimmed = selAddress.pincode.replace(
      /[^a-zA-Z0-9,()-+ ]/g,
      ""
    );
    const landmarkTrimmed = selAddress.landmark.replace(
      /[^a-zA-Z0-9,() ]/g,
      ""
    );
    const shippingAddressData =
      "<div><p>" +
      nameTrimmed +
      "</p>" +
      "<p>" +
      selAddress.mobile +
      "," +
      selAddress.alternateMobile +
      "</p><p>Address: " +
      addressTrimmed +
      "</p><p>City: " +
      cityTrimmed +
      "</p><p>State: " +
      selAddress.state +
      "</p><p>Pincode: " +
      pincodeTrimmed +
      "</p><p>Landmark: " +
      landmarkTrimmed +
      "</p></div>";
    // console.log("orderSummaryTable:", orderSummaryTable);
    // console.log("shippingAddress:", shippingAddressData);
    const options = JSON.stringify({
      cust_name: customerName,
      cust_email: customerEmail,
      from_name: "Inproveda Orders",
      from_email: "orders@inproveda.com",
      bcc_name: "Arpit Goyal",
      bcc_email: "arpitgoyal138@gmail.com",
      order_id: orderId,
      brand_name: "Inproveda",
      order_summary: orderSummaryTable,
      shipping_address: shippingAddressData,
    });
    try {
      await fetch(url, {
        method: "POST",
        body: options,
        headers: {
          "content-type": "application/json",
        },
      })
        .then((response) => {
          response.json();
        })
        .then((data) => {
          //console.log("data:", data);
        });
    } catch (error) {
      console.log("Server Error:", error);
    }
  };
  const handlePaymentUpdate = (props) => {
    // console.log(
    //   "handlePaymentUpdate props:",
    //   props,
    //   "__currentOrder:",
    //   currentOrder,
    //   "__user:",
    //   user
    // );

    const { success, response } = props;
    if (success) {
      sendMail();
      // Payment Completed
      const payment_id = response.razorpay_payment_id;
      const dataForOrderTable = {
        payload: {
          payment: {
            method: "online",
            status: "completed",
            payment_id: payment_id,
            attempted_at: Date().toString(),
          },
          status: "Placed",
        },
        id: currentOrder.id,
      };
      ordersAPI.setOrder(dataForOrderTable).then((resOrder) => {
        // console.log("RES ORDER:", resOrder);
        const prevOrders = user.orders !== undefined ? user.orders : [];
        //console.log("prevOrders:", prevOrders);
        //update object in prevOrders where order id = currentOrder.id
        if (prevOrders.length === 0) {
          prevOrders.push({ ...currentOrder });
        }
        const updatedOrders = prevOrders.map((order) => {
          if (order.id === currentOrder.id) {
            return { ...order, ...dataForOrderTable.payload };
          }
          return order;
        });
        const dataForUsersTable = {
          id: user.id,
          payload: {
            orders: [...updatedOrders],
          },
        };
        //console.log("dataForUsersTable with updated order:", dataForUsersTable);
        userDataApi.addUserData(dataForUsersTable).then((res) => {
          // console.log("RES from user data update:", res);
          if (res.success) {
            userProfileContext.setState((prev) => ({
              ...prev,
              cartItems: [],
              couponApplied: null,
              shippingCost: 0,
              codCharges: 0,
              orders: dataForUsersTable.payload.orders,
            }));
            // navigate to order placed page
            navigate("/orderPlaced");
          }
        });
      });
    } else {
      // Payment failed
      console.log("Payment Failed");
    }
  };

  const handleCloseCOD = () => {
    setOpenDialog(false);
  };
  const handleAgreeCOD = () => {
    //console.log("user:", user);
    //handle confirm COD
    // generate random number of 8 characters based on timestamp
    const timeString = new Date().getTime().toString();
    let randNum = timeString.slice(-8);
    let randomOrderId = `COD${randNum}`;
    //console.log("timeString:", timeString, " ____ randomOrderId:", randomOrderId);
    const fAmount =
      cartItems.reduce((a, v) => (a = a + v.listPrice * v.quantity), 0) +
      shippingAmount +
      codCharges -
      couponDiscount;
    const codOrder = {
      id: randomOrderId,
      amount: fAmount,
      shippingCost: shippingAmount,
      codCharges: codCharges,
      couponApplied: userProfileContext.state.couponApplied
        ? userProfileContext.state.couponApplied
        : null,
      status: "Placed",
      payment: {
        method: "cod",
        status: "pending",
      },
      user: {
        id: user.id,
        name: user.displayName,
        email: user.email,
      },
      items: [...cartItems],
      address: selAddress,
      createdAt: new Date().toString(),
    };
    //console.log("codOrder:", codOrder);
    const dataForOrderTable = {
      payload: {
        ...codOrder,
      },
      id: randomOrderId,
    };
    ordersAPI.setOrder(dataForOrderTable).then((resOrder) => {
      // console.log("RES ORDER:", resOrder);
      const prevOrders = user.orders !== undefined ? user.orders : [];
      const dataForUsersTable = {
        id: user.id,
        payload: {
          orders: [{ ...codOrder }, ...prevOrders],
        },
      };
      // console.log("dataForUsersTable:", dataForUsersTable);
      userDataApi.addUserData(dataForUsersTable).then((res) => {
        // console.log("RES from user data update:", res);
        if (res.success) {
          userProfileContext.setState((prev) => ({
            ...prev,
            cartItems: [],
            couponApplied: null,
            orders: dataForUsersTable.payload.orders,
          }));
        }
        //check if coupon applied
        if (user.couponApplied !== undefined && user.couponApplied !== null) {
          const couponPrevUsers = user.couponApplied.users
            ? user.couponApplied.users
            : [];
          const dataForCouponsTable = {
            payload: {
              users: [...couponPrevUsers, user.id],
            },
            id: user.couponApplied.id,
          };
          couponsAPI
            .setCoupon(dataForCouponsTable)
            .then((res) => {
              //console.log("Coupon updated");
              sendMail(randomOrderId);
              // navigate to order placed page
              navigate("/orderPlaced");
            })
            .catch((err) => {
              console.log("coupon update error:", err);
            });
        } else {
          sendMail(randomOrderId);
          // navigate to order placed page
          navigate("/orderPlaced");
        }
      });
    });
    setOpenDialog(false);
  };
  const steps = [
    {
      label: "Customer Info",
      component: <LoginStep user={user} loading={loading} />,
      isCompleted: loginCompleted,
      subtitle:
        user !== null
          ? `${user.displayName} (${user.email})`
          : "Please Login first",
    },
    {
      label: "Delivery Address",
      component: (
        <SelectAddressStep
          setCompleted={setAddressCompleted}
          selAddress={selAddress}
          setSelAddress={setSelAddress}
        />
      ),
      isCompleted: addressCompleted,
      subtitle:
        selAddress !== null ? selAddress.address : "Where we should deliver ?",
    },
    {
      label: "Order Summary",
      component: <OrderSummary items={cartItems} />,
    },
  ];

  return (
    <div style={{ height: "100%" }}>
      {!mobileScreen && (
        <HorizontalLinearStepper
          steps={steps}
          activeStep={activeStep}
          handleStep={handleStep}
          handleClose={handleClose}
        />
      )}
      {mobileScreen && (
        <VerticalLinearStepper
          steps={steps}
          activeStep={activeStep}
          handleStep={handleStep}
          handleClose={handleClose}
        />
      )}
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item xs={12} sm={4}>
          {activeStep === steps.length - 1 && (
            <>
              <CustomButton
                fullWidth
                size="small"
                variant="outlined"
                onClick={() => setOpenDialog(true)}
                sx={{
                  display:
                    process.env.REACT_APP_ENABLE_COD === "TRUE" ? "" : "none",
                }}
                disabled={codCharges > 0 ? false : true}
              >
                Pay on delivery
              </CustomButton>
              <AlertDialogSlide
                handleOnAgree={handleAgreeCOD}
                handleOnClose={handleCloseCOD}
                isOpen={openDialog}
                title="Place order"
                agreeButtonText="Confirm"
                cancelButtonText="Cancel"
                description={"Sure to place order using Cash on delivery ?"}
              />
            </>
          )}
        </Grid>
        <Grid item xs={12} sm={4}>
          {activeStep === steps.length - 1 && (
            <CheckoutButton
              user={userProfileContext.state}
              amount={finalAmount}
              onCreateOrder={handleCreateOrder}
              currentOrder={currentOrder}
              onPayment={handlePaymentUpdate}
              disabled={codCharges > 0 ? true : false}
            />
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default PlaceOrder;
