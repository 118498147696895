import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import { Box, Container, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import "./styles.css";
import AuthAPI from "../../../api/firebase/AuthAPI";
import OrdersAPI from "../../../api/firebase/OrdersAPI";
import ProductsAPI from "../../../api/firebase/ProductsAPI";
import CustomButton from "../../../components/common/Button/CustomButton";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import GridViewIcon from "@mui/icons-material/GridView";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
const AdminDashboard = () => {
  const navigate = useNavigate();
  const initOrdersSpecs = {
    Placed: 0,
    Shipped: 0,
    Delivered: 0,
    Cancelled: 0,
    Refunded: 0,
  };
  const initProductsSpecs = {
    Hidden: 0,
    OutOfStock: 0,
    LessThanFiveUnits: 0,
  };
  const initSalesSpecs = {
    TotalSaleAmount: 0,
    SettledAmount: 0,
    EligibleForRefund: 0,
    RefundedAmount: 0,
  };
  const initUsersSpecs = {
    Customers: 0,
    Active: 0,
    Inactive: 0,
  };
  const ordersAPI = new OrdersAPI();
  const [ordersArr, setOrdersArr] = useState([]);
  const [ordersLoading, setOrdersLoading] = useState(true);
  const [ordersSpecs, setOrdersSpecs] = useState(initOrdersSpecs);

  const authAPI = new AuthAPI();
  const [usersArr, setUsersArr] = useState([]);
  const [usersLoading, setUsersLoading] = useState(true);
  const [usersSpecs, setUsersSpecs] = useState(initUsersSpecs);

  const productsAPI = new ProductsAPI();
  const [productsArr, setProductsArr] = useState([]);
  const [productsLoading, setProductsLoading] = useState(true);
  const [productsSpecs, setProductsSpecs] = useState(initProductsSpecs);

  const [salesLoading, setSalesLoading] = useState(true);
  const [salesSpecs, setSalesSpecs] = useState(initSalesSpecs);

  useEffect(() => {
    ordersAPI
      .getOrders()
      .then((res) => {
        console.log("Orders:", res.data);
        res.data = res.data.filter((order) => order.status !== "CREATED");
        if (res.success) {
          if (res.data.length > 0) {
            setOrdersArr([...res.data]);
          } else {
            setOrdersLoading(false);
          }
        } else {
          setOrdersArr([]);
          setOrdersLoading(false);
        }
      })
      .catch((err) => {
        console.log("Error while Fetch Orders:", err);
        setOrdersLoading(false);
      });
    productsAPI
      .getProducts()
      .then((res) => {
        console.log("Products:", res.data);
        if (res.success) {
          if (res.data.length > 0) {
            setProductsArr([...res.data]);
          } else {
            setProductsLoading(false);
          }
        } else {
          setProductsArr([]);
          setProductsLoading(false);
        }
      })
      .catch((err) => {
        console.log("Error while Fetch Products:", err);
        setProductsLoading(false);
      });
    authAPI
      .getAllUsers()
      .then((res) => {
        if (res.success) {
          if (res.data.length > 0) {
            const exceptAdmin = res.data.filter((user) => {
              return !user.role.includes("Admin");
            });
            console.log("Users:", res.data);

            setUsersArr([...exceptAdmin]);
          } else {
            setUsersLoading(false);
          }
        } else {
          setUsersArr([]);
          setUsersLoading(false);
        }
      })
      .catch((err) => {
        console.log("Error while Fetch Users:", err);
        setUsersLoading(false);
      });
  }, []);

  useEffect(() => {
    if (ordersArr.length > 0) {
      //get number of count where status in ordersArr is Placed
      let placedCount = ordersArr.filter(
        (order) => order.status === "Placed"
      ).length;
      // get  number of count where status in ordersArr is Shipped or Out for delivery
      let shippedCount = ordersArr.filter(
        (order) =>
          order.status === "Shipped" || order.status === "Out for delivery"
      ).length;
      let deliveredCount = ordersArr.filter(
        (order) => order.status === "Delivered"
      ).length;
      let cancelledCount = ordersArr.filter(
        (order) =>
          order.status === "Cancelled by buyer" ||
          order.status === "Cancelled by seller"
      ).length;
      let refundedCount = ordersArr.filter(
        (order) => order.status === "Refunded"
      ).length;
      setOrdersSpecs({
        Placed: placedCount,
        Shipped: shippedCount,
        Delivered: deliveredCount,
        Cancelled: cancelledCount,
        Refunded: refundedCount,
      });
      setTimeout(() => {
        setOrdersLoading(false);
      }, 500);
      //get total amount of orders
      const totalSaleAmount = ordersArr.reduce((acc, currOrder) => {
        return acc + parseFloat(currOrder.amount);
      }, 0);
      //get total amount of orders settled
      const totalSettledAmount = ordersArr.reduce((acc, currOrder) => {
        if (currOrder.status !== "Delivered") {
          return acc;
        }
        const deliveredAt = new Date(currOrder.deliveredAt);
        const dateNow = new Date();
        const diffTime = Math.abs(dateNow - deliveredAt);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        if (diffDays > 6) {
          return acc + parseFloat(currOrder.amount);
        } else {
          return acc;
        }
      }, 0);
      //get total refunded amount
      const totalRefundedAmount = ordersArr.reduce((acc, currOrder) => {
        if (currOrder.status !== "Refunded") {
          return acc;
        }
        return acc + parseFloat(currOrder.amount);
      }, 0);

      setSalesSpecs({
        TotalSaleAmount: totalSaleAmount,
        SettledAmount: totalSettledAmount,
        EligibleForRefund:
          totalSaleAmount - totalSettledAmount - totalRefundedAmount,
        RefundedAmount: totalRefundedAmount,
      });
      setTimeout(() => {
        setSalesLoading(false);
      }, 500);
    }
  }, [ordersArr]);
  useEffect(() => {
    if (productsArr.length > 0) {
      //get number of count where visible in false
      let hiddenCount = productsArr.filter(
        (product) => product.visible === false
      ).length;
      // get  number of count where product is outOfStock
      let outOfStockCount = productsArr.filter(
        (product) => product.unitsInStock === 0
      ).length;
      // get  number of count where product is outOfStock
      let lessThan5Count = productsArr.filter(
        (product) => product.unitsInStock < 5
      ).length;
      setProductsSpecs({
        Hidden: hiddenCount,
        OutOfStock: outOfStockCount,
        LessThanFiveUnits: lessThan5Count,
      });
      setTimeout(() => {
        setProductsLoading(false);
      }, 500);
    }
  }, [productsArr]);
  useEffect(() => {
    if (usersArr.length > 0) {
      //get number of count where visible in false
      let customersCount = usersArr.filter(
        (user) => user.orders !== undefined && user.orders.length > 0
      ).length;
      console.log("customersCount:", customersCount);
      //get total num of users who logged in last 5 days
      const activeUsersCount = usersArr.reduce((acc, currUser) => {
        if (currUser.loginAt === undefined) {
          return acc;
        }
        const loginAt = new Date(currUser.loginAt);
        const dateNow = new Date();
        const diffTime = Math.abs(dateNow - loginAt);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        if (diffDays < 5) {
          return acc + 1;
        } else {
          return acc;
        }
      }, 0);

      setUsersSpecs({
        Customers: customersCount,
        Active: activeUsersCount,
        Inactive: usersArr.length - activeUsersCount,
      });
      setTimeout(() => {
        setUsersLoading(false);
      }, 500);
    }
  }, [usersArr]);
  return (
    <>
      <Box component="div" sx={{ display: { xs: "none", sm: "block" } }}>
        <Typography
          sx={{
            marginBottom: "2rem",
          }}
          variant="h4"
          component="h4"
        >
          Dashboard
        </Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} lg={3}>
          <Paper
            className="dashboard-box orders"
            onClick={() => navigate("/admin/orders")}
          >
            <Container
              maxWidth="sm"
              style={{ height: "100%", padding: "1rem" }}
            >
              <Box sx={{ textAlign: "left" }}>
                <ShoppingCartIcon sx={{ fontSize: 40 }} />
                <Typography
                  sx={{ textAlign: "center", mt: "-0.5rem" }}
                  variant="h5"
                  align="left"
                >
                  Orders ({ordersArr.length})
                </Typography>
              </Box>

              <Box sx={{ textAlign: "left", mt: 1 }}>
                <Typography className="box-list" variant="p" component="div">
                  Yet to be Shipped: {ordersSpecs.Placed}
                </Typography>
                <Typography className="box-list" variant="p" component="div">
                  On the way: {ordersSpecs.Shipped}
                </Typography>
                <Typography className="box-list" variant="p" component="div">
                  Delivered: {ordersSpecs.Delivered}
                </Typography>
                <Typography className="box-list" variant="p" component="div">
                  Cancelled: {ordersSpecs.Cancelled}
                </Typography>
                {/* <Typography className="box-list" variant="p" component="div">
                  Refunded: {ordersSpecs.Refunded}
                </Typography> */}
              </Box>
            </Container>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <Paper
            className="dashboard-box products"
            onClick={() => navigate("/admin/products")}
          >
            <Container
              maxWidth="sm"
              style={{ height: "100%", padding: "1rem" }}
            >
              <Box sx={{ textAlign: "left" }}>
                <GridViewIcon sx={{ fontSize: 40 }} />

                <Typography variant="h5" align="center" sx={{ mt: "-0.5rem" }}>
                  Products ({productsArr.length})
                </Typography>
              </Box>
              <Box sx={{ textAlign: "left", mt: 2 }}>
                <Typography className="box-list" variant="p" component="div">
                  Hidden: {productsSpecs.Hidden}
                </Typography>
                <Typography className="box-list" variant="p" component="div">
                  Out of stock: {productsSpecs.OutOfStock}
                </Typography>
                <Typography className="box-list" variant="p" component="div">
                  Less Than 5 Units: {productsSpecs.LessThanFiveUnits}
                </Typography>
              </Box>
            </Container>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <Paper
            className="dashboard-box  sales"
            // onClick={() => navigate("/admin/sales")}
          >
            <Container
              maxWidth="sm"
              style={{ height: "100%", padding: "1rem" }}
            >
              <Box sx={{ textAlign: "left" }}>
                <CurrencyRupeeIcon sx={{ fontSize: 40 }} />

                <Typography variant="h5" align="center" sx={{ mt: "-0.5rem" }}>
                  Sales (₹ {salesSpecs.TotalSaleAmount})
                </Typography>
              </Box>
              <Box sx={{ textAlign: "left", mt: 2 }}>
                <Typography className="box-list" variant="p" component="div">
                  Total Sale Amount : ₹ {salesSpecs.TotalSaleAmount}
                </Typography>
                <Typography className="box-list" variant="p" component="div">
                  Settled Amount: ₹ {salesSpecs.SettledAmount}
                </Typography>
                <Typography className="box-list" variant="p" component="div">
                  Eligible for Refund: ₹ {salesSpecs.EligibleForRefund}
                </Typography>
                {/* <Typography className="box-list" variant="p" component="div">
                  Refunded Amount: ₹ {salesSpecs.RefundedAmount}
                </Typography> */}
              </Box>
            </Container>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <Paper
            className="dashboard-box users"
            onClick={() => navigate("/admin/users")}
          >
            <Container
              maxWidth="sm"
              style={{ height: "100%", padding: "1rem" }}
            >
              <Box sx={{ textAlign: "left" }}>
                <PeopleAltIcon sx={{ fontSize: 40 }} />
                <Typography variant="h5" align="center" sx={{ mt: "-0.5rem" }}>
                  Users ({usersArr.length})
                </Typography>
              </Box>
              <Box sx={{ textAlign: "left", mt: 2 }}>
                <Typography className="box-list" variant="p" component="div">
                  Customers: {usersSpecs.Customers}
                </Typography>
                <Typography className="box-list" variant="p" component="div">
                  Active: {usersSpecs.Active}
                </Typography>
                <Typography className="box-list" variant="p" component="div">
                  Inactive: {usersSpecs.Inactive}
                </Typography>
              </Box>
            </Container>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default AdminDashboard;
