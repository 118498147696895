import { Container, Typography } from "@mui/material";
import React from "react";

const TermsAndConditions = () => {
  return (
    <Container maxWidth="xl">
      <Typography variant="h4" mt="2rem">
        Terms and conditions
      </Typography>
      <Typography variant="body1" mt="2rem">
        Please read the below points to understand the expressions under each
        circumstance before you have a wonderful shopping experience.
      </Typography>
      <Typography variant="h5" mt="2rem">
        Definitions
      </Typography>
      <Typography variant="body1" mt="1rem">
        While reading further, you may find several words which convey specific
        meaning:
      </Typography>
      <Typography variant="body1" mt="1rem">
        <ul>
          <li>"Buyer" means the person who buys goods from the Seller</li>
          <li>
            "Seller" means inproveda.com, who sell ayurvedic and herbal products
          </li>
          <li>
            "Goods" means the products offered by the seller for sale on the web
            site
          </li>
          <li>
            "List Price" means the prices listed on the website for goods by the
            Seller for sale or as revised from time to time
          </li>
          <li>
            "Terms and Conditions" means the terms and conditions of sale set
            out in this document and as amended from time to time
          </li>
        </ul>
      </Typography>
      <Typography variant="body1" component="p" mt="1rem">
        The use of this website, its services and tools are all governed by the
        below mentioned terms and conditions. If you make any transaction
        through this website, you shall undergo policies that are applicable to
        the website. If you transact with this website means you are contracting
        with inproveda.com and you shall follow the duties with this company.
      </Typography>
      <Typography variant="body1" component="p" mt="1rem">
        Please note that wherever is mentioned as "you" or "user" shall be
        considered as the member of the website who registers with inproveda.com
        and provides his/her data using the computation systems. Inproveda
        allows its users to surf from this website without registration process.
        The term "we", "us", "our" means the company or inproveda.com.
      </Typography>
      <Typography variant="body1" component="p" mt="1rem">
        When you use this website including customer reviews, you are subject to
        guidelines, rules, policies, terms and conditions applicable to this
        website and its services. We reserve the sole right to modify, add,
        change or remove the portion or content of these Terms and Conditions
        without any notification.
      </Typography>
      <Typography variant="body1" component="p">
        Please be certain that with the use of Terms & Conditions, we offer you
        limited privilege to enter this website and enjoy non-exclusive,
        non-transferable, personal account.
      </Typography>
      <Typography variant="h5" mt="2rem">
        Limitations
      </Typography>
      <Typography variant="body1" component="p" mt="1rem">
        Our shipments are carefully inspected before the items leave our
        warehouse, but if you are not satisfied with your purchase, please
        follow the instructions below:
      </Typography>
      <Typography variant="body1" mt="1rem">
        <ul>
          <li>
            The seller will not be responsible for any damage, direct loss
            suffered by the buyer due to any negligence, breach of contract or
            excessive pricing of the goods.
          </li>
          <li>
            The seller is not responsible for any economic loss, loss of profit
            or any indirect third party loss suffered by the buyer caused due to
            any reason such as negligence, failure to meet any estimated
            delivery date, misrepresentation or breach of contract.
          </li>
          <li>
            All offers for goods purchased by buyers shall be treated as offers
            listed under the clauses given in Terms & Conditions (T&C). The
            seller may choose not to accept or cancel a particular order without
            giving any particular reason.
          </li>
          <li>
            Placing an order on inproveda.com will be treated as an evidence for
            acceptance of T&C on inproveda.com
          </li>
          <li>
            Any changes mentioned in T&C will be considered inapplicable unless
            the seller agrees the same in writing
          </li>
        </ul>
      </Typography>
      <Typography variant="h5" mt="2rem">
        Severance
      </Typography>
      <Typography variant="body1" mt="1rem">
        <ul>
          <li>
            inproveda.com does not regularly monitors your posting to the
            website, but reserves the sole right to do so and if found
            inappropriate will be responding immediately with the right to
            report to the law enforcement authorities or legal bodies to action
            the same.
          </li>
          <li>
            inproveda.com will completely cooperate for investigation of illegal
            activities on the internet If any provision of these T&C is held
            invalid or illegal by the court of jurisdiction for any reason, such
            provision shall be severed and the remaining provisions will be
            continued in full effect until invalid and illegal contents are
            eliminated.
          </li>
          <li>
            Submission of invalid or unauthorized contents / materials violates
            the copyright laws, laws of privacy and publicity, trademark laws
            and other regulations. In this case, user who posts such illegal
            contents shall be held responsible for such action or the person
            using the account by submitting user name and password.
          </li>
          <li>
            You shall indemnify inproveda.com and hold its officers, employees,
            directors, affiliates, business partners, agents and licensors
            innocent against any loss, damages, expenses, cost and liabilities
            incurred due to the negligence for the claim or demand from a third
            party for your use of the website or any person using your account
            on this website.
          </li>
          <li>
            inproveda.com Reserves the right to stop your access to this website
            at any time without giving any prior notice. This license can
            terminate automatically if you breach any of these terms. If you are
            terminated then you must first destroy any downloaded and printed
            materials.
          </li>
          <li>
            {" "}
            By browsing and transacting this website, you accept the current
            terms and conditions which may change from time to time.
          </li>
          <li>
            inproveda.com may suspend the operation of this website for some
            time being for maintenance work in order to update content or for
            any other reason.
          </li>
        </ul>
      </Typography>
      <Typography variant="h5" mt="2rem">
        Warranty
      </Typography>
      <Typography variant="body1" mt="1rem">
        As per the license, the goods that are to be delivered should resemble
        to the description mentioned by the seller on the website or the buyer
        has to give approval for any change.
      </Typography>
      <Typography variant="h5" mt="2rem">
        Price, Payment and Delivery
      </Typography>
      <Typography variant="body1" mt="1rem">
        <ul>
          <li>
            Pricing of the goods shall be specified similar as given in seller's
            current list price on the website inclusive of all application taxes
            and will be delivered to the address registered by the user on the
            website within 24 hours from the time of the orders that are
            successfully placed (subject to availability).
          </li>
          <li>
            Full payment of cash on delivery option shall be applicable for home
            delivery orders only.
          </li>
          <li>
            The Risk and Rewards are transferred to the Buyer of the goods when
            the Invoice is prepared by the Seller in the POS System.
          </li>
          <li>
            The seller may decline sending any orders if the customer is found
            suspended for any reason.
          </li>
          <li>
            The seller reserves the right to change and make correct pricing of
            goods if any error persists in the description of goods or pricing
            in the website catalogue.
          </li>
        </ul>
      </Typography>
      <Typography variant="h5" mt="2rem">
        Unforeseeable Circumstances
      </Typography>
      <Typography variant="body1" mt="1rem">
        If the products are delayed or failed for any reason from the events or
        circumstances which are outside its reasonable control, neither of the
        parties will be held responsible for it (controls shall also include
        strikes, act of god, lock outs, accidents, breakdown of
        plant/machinery/shortage/unavailability of raw materials from a natural
        source of supply, and war). In such case, the party is entitled for the
        extension on delivery.
      </Typography>
      <Typography variant="h5" mt="2rem">
        User Eligibility
      </Typography>
      <Typography variant="body1" mt="1rem">
        <ul>
          <li>
            inproveda.com website is available only to those users who can form
            legally binding contracts under the Indian Contract Act, 1872. Those
            who cannot follow the norms or are incompetent to the contract
            (including minors, undischarged bankrupts, etc.) are not eligible to
            use this website.
          </li>
          <li>
            If you are a minor or under the age of 18 years, you shall not be a
            user of inproveda.com and shall not transact on this website.
          </li>
          <li>
            inproveda.com reserves the right to terminate your account and
            refuse your access if it is brought to inproveda.com's notice or if
            discovered that you are underage to proceed
          </li>
        </ul>
      </Typography>
      <Typography variant="h5" mt="2rem">
        Your Account and Registration Obligations
      </Typography>
      <Typography variant="body1" mt="1rem">
        <ul>
          <li>
            If you are using this website, you are responsible for the
            maintenance of confidentiality of your User ID and Password and only
            you shall be responsible for all the activities on the website.
          </li>
          <li>
            With the use of this website, you agree to this that providing any
            information that is inaccurate, untrue, incomplete, not as per the
            terms of use then in that case we reserve the right to suspend or
            terminate or block access of your membership on the website.
          </li>
          <li>
            Personal details provided by you to inproveda.com through account
            creation or order placement will only be used in accordance with our
            privacy policy and shall not be used for any purpose other than
            sending you emails and SMS with offers of your interest or as
            directed by law. By providing your personal details, you are
            agreeing to our privacy policy.
          </li>
        </ul>
      </Typography>
      <Typography variant="h5" mt="2rem">
        Communications
      </Typography>
      <Typography variant="body1" mt="1rem">
        If you use inproveda.com or send emails to other data, communication or
        information to use, you are agreeing that you are communicating with us
        via electronic records and you agree to receive communications likewise.
        We may communicate with you via electronic records or any other mode of
        communication (electronic or otherwise).
      </Typography>
      <Typography variant="h5" mt="2rem">
        Charges
      </Typography>
      <Typography variant="body1" mt="1rem">
        <ul>
          <li>
            There are no charges applicable for the registration on
            inproveda.com. You can become a member free of cost and enjoy the
            offers. However, we reserve the right to charge you nominal fee and
            change its policies over the time. We reserve the right to introduce
            new services and modify few or all existing services offered on the
            website without any notice.
          </li>
          <li>
            Once changes related to fees and policies are uploaded on the
            website, it shall immediately become effective. All fees to be
            quoted in Indian Rupees unless otherwise stated. With the use of
            this website, you agree to this that you are responsible for all
            applicable laws including those in India for making payments.
          </li>
        </ul>
      </Typography>
      <Typography variant="h5" mt="2rem">
        Use of the Website
      </Typography>
      <Typography variant="body1" mt="1rem">
        Your access to this website does not allow you to host, modify, display,
        Upload, transmit, publish, share, update any information which:
      </Typography>
      <Typography variant="body1" mt="1rem">
        <ul>
          <li>Belongs to other person and you don't hold any right to</li>
          <li>
            Is harassing, defamatory, encouraging gambling or money laundering,
            hateful, racially, invasive of other's privacy, obscene,
            pornographic, ethnically objectionable, unlawful, threatening,
            indecent representation of women under the Indecent Representation
            of Women (Prohibition) Act, 1986 (misleading, patiently offensive to
            community including sexually explicit content, promotes racism,
            hatred, physical harm and otherwise)
          </li>
          <li>
            Involves transmission of chain letters and junk mail, mass mailing
            or spam
          </li>
          <li>Harassment of another person</li>
          <li>
            Promotes illegal activities such as obscene, abusive, threatening,
            libelous and whatsoever
          </li>
          <li>
            Violates third party rights (intellectual property right, privacy,
            email address, physical address, phone no., person's name)
          </li>
          <li>
            {" "}
            Promotes unauthorized copy of other person's copyrighted work such
            as offering pirated computer programs or links, providing
            information for copy of manufacture-installed devises, pirated music
            or links or files
          </li>
          <li>Hidden pages or images linked from another accessible page</li>
          <li>
            Adding materials that exploit other user's violent, sexual or other
            inappropriate manner
          </li>
          <li>
            Provides information related to buying of illegal weapons, creating
            computer viruses or violating someone's privacy
          </li>
          <li>Includes any person's images or photographs (minor or adult)</li>
          <li>
            Unauthorized access to website, profiles, communities, blogs, friend
            request or other areas for unlawful purpose
          </li>
          <li>
            Indulging in commercial activities or sales without our written
            consent such as keeping contests, buying or selling virtual items
            related to our website which promotes gambling and is illegal
          </li>
          <li>
            Trespass any patent, copyright, trademark or other proprietary
            rights (Refers to any website that contains content which violates
            or prohibits the terms of use)
          </li>
          <li>
            Unauthorized attempt of adding deep-link, robot, algorithm, or any
            other attempt
          </li>
          <li>
            Direct or indirect offer, attempt to offer, trade or attempt for the
            same in any item, dealing for the same is prohibited or restricted
          </li>
          <li>
            Unauthorized access or attempt to gain so to any portion/feature of
            the website or networks that connects the website or server,
            computer or any other hacking, password or illegitimate use
          </li>
          <li>
            You shall not be making any defamatory, negative comment(s) or
            statement(s) against inproveda.com
          </li>
          <li>
            You shall not be a part of any advertising or create on your own for
            buying and selling of products and services on this website and
            transmit any chain letters, junk email, or related to other users
            through this website
          </li>
        </ul>
      </Typography>
      <Typography variant="h5" mt="2rem">
        Contents Posted on Site
      </Typography>
      <Typography variant="body1" mt="1rem">
        All graphics, designs, interfaces, photographs, logos, trademarks,
        sounds, computer code, music, artwork, visual interfaces, contents are
        all owned and controlled by us; licensed, copyright, trademark laws, and
        various other property and competition laws included. In this context,
        no content must be uploaded, copied, reproduced, posted, displayed,
        encoded, transmitted or distributed in any way
      </Typography>
      <Typography variant="h5" mt="2rem">
        Other Businesses
      </Typography>
      <Typography variant="body1" mt="1rem">
        inproveda.com is not responsible for the products, actions, content and
        services on the Website that are connected to Affiliates and / or third
        party websites using Website's APIs or otherwise. inproveda.com may
        provide links to the third party websites of our affiliated companies
        and few other businesses for which, we are not responsible for
        evaluating or examining the products and services offered by them. We
        don't endorse any third party website(s) or content.
      </Typography>
      <Typography variant="h5" mt="2rem">
        Links
      </Typography>
      <Typography variant="body1" mt="1rem">
        We appreciate links to the Website. User may create hypertext to link to
        this Website, but the link should not state or suggest any sponsorship
        or endorsement of your site by us. You are not eligible to use our
        trademarks, service marks, logos or any other marks or materials by the
        owner.
      </Typography>
      <Typography variant="h5" mt="2rem">
        Privacy
      </Typography>
      <Typography variant="body1" mt="1rem">
        We ensure to keep your information protected and understand that your
        personal information that you have shared with us are very important
        assets. If you object to share your personal information being
        transferred or used as per the Information Technology Act, 2000, you
        shall not be using this website.
      </Typography>
      <Typography variant="h5" mt="2rem">
        Mandatory declaration
      </Typography>
      <Typography variant="body1" mt="1rem">
        By providing your personal information, you hereby admit that you are
        fine for all the purchased products being shipped to your given address.
        The products will be resold, bartered or sold in any way for profit or
        anti-national use. With this, you declare that all the products are to
        be treated with utmost respect and caution with not illegal activity
        being taken on.
      </Typography>
      <Typography variant="h5" mt="2rem">
        Copyright complaint
      </Typography>
      <Typography variant="body1" mt="1rem">
        If you find your work has been copied constituting copyright
        infringement, you can write to us at support@inproveda.com
      </Typography>
      <Typography variant="h5" mt="2rem">
        Risk of loss
      </Typography>
      <Typography variant="body1" mt="1rem">
        All the items are checked and shipped from our warehouse. We have a
        separate shipment contract and if any damage persists while receiving
        the product or risk of loss, the title for such products pass to you by
        the carrier for delivery of products.
      </Typography>

      <Typography variant="h5" mt="2rem">
        Pricing or Typographical error
      </Typography>
      <Typography variant="body1" mt="1rem">
        There may be typo error on the pricing or description on the website. In
        this case, inproveda.com holds the right to rectify changes and update
        the right price or detail. Also, inproveda.com holds the right to cancel
        the order and refund money back to its customer if the product is
        collected from you with 10-15 business days. The mode of refund will be
        same as per how you place the order.
      </Typography>
      <Typography variant="h5" mt="2rem">
        Grievance officer
      </Typography>
      <Typography variant="body1" mt="1rem">
        In accordance with Information Technology Act 2000 and rules made there
        under, the name and contact details of the Grievance Officer are
        provided below.
      </Typography>
      <Typography variant="h5" mt="2rem">
        Our Contact
      </Typography>
      <Typography variant="body1" mt="1rem">
        If you have a question or complaint, please write to us at
        support@inproveda.com or call us on +91-9411174186 from Monday to
        Saturday between 09:30 am to 06:30 pm (during national holidays our
        offices may remain shut). We will take this to our grievance officer on
        immediate notice.
      </Typography>
    </Container>
  );
};

export default TermsAndConditions;
