import React, { useState, useEffect } from "react";
import {
  Collapse,
  Container,
  Divider,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ProductsContainer from "../../components/app/Products/ProductsContainer";
import VerticalLinearStepper from "../../components/common/Stepper/VerticalLinearStepper";
import CustomButton from "../../components/common/Button/CustomButton";
import AlertDialogSlide from "../../components/common/Dialog/AlertDialogSlide";
import Loader from "../../components/loader/Loader";
import UserDataAPI from "../../api/firebase/UserDataAPI";
import OrdersAPI from "../../api/firebase/OrdersAPI";
import UserProfileContext from "../../context/userProfile/UserProfileContext";
import { useContext } from "react";
import { useRef } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import useRazorpay from "react-razorpay";
const OrderDetailPage = () => {
  const navigate = useNavigate();
  const [Razorpay] = useRazorpay();
  const userDataApi = new UserDataAPI();
  const ordersAPI = new OrdersAPI();
  const userProfileContext = useContext(UserProfileContext);
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [cancelOrder, setCancelOrder] = useState(false);
  const [orderSteps, setOrderSteps] = useState([]);
  const activeStepRef = useRef(0);
  const [user, setUser] = useState(null);
  const [itemsAmount, setItemsAmount] = useState(0);
  const [couponDis, setCouponDis] = useState(0);
  const [shippingAmount, setShippingAmount] = useState(0);
  const location = useLocation();
  const order = location.state.order;
  // console.log("order:", order);
  let steps = [];
  const isToday = (dateString) => {
    const today = new Date();
    if (today.toDateString() === dateString) {
      return true;
    }
    return false;
  };
  useEffect(() => {
    if (order !== undefined) {
      let date = new Date(order.createdAt);
      let createdDate = new Date(date).toDateString();
      let label_createdAt = "";
      if (isToday(createdDate)) {
        label_createdAt = "Order confirmed Today";
      } else {
        let date_ = new Date(order.createdAt);
        let num_ = date_.setDate(date_.getDate() + 1);
        let check_date = new Date(num_).toDateString();
        if (isToday(check_date)) {
          label_createdAt = "Order confirmed Yesterday";
        } else {
          label_createdAt =
            "Order confirmed on " + createdDate.substring(0, 10);
        }
      }
      let ex_ship_date = date.setDate(date.getDate() + 2);
      let expectedSD = new Date(ex_ship_date).toDateString().substring(0, 10);

      let ex_delivery_date = date.setDate(date.getDate() + 6);
      let expectedDD = new Date(ex_delivery_date)
        .toDateString()
        .substring(0, 10);
      switch (order.status) {
        case "CREATED":
          activeStepRef.current = 0;
          steps = [
            {
              label: isPaymentProcessing(order)
                ? "Payment under processing"
                : "Payment not completed",
              isCompleted: false,
              subtitle: isPaymentProcessing(order)
                ? "You can retry payment after few minutes"
                : "Please pay the amount to confirm the order",
            },
            {
              label: "Confirmed",
              isCompleted: false,
              subtitle: "",
            },
          ];
          break;
        case "Placed":
          activeStepRef.current = 0;
          steps = [
            {
              label: "Confirmed",
              isCompleted: true,
              subtitle: label_createdAt,
            },
            {
              label: "Shipped",
              isCompleted: false,
              subtitle: "Expected by " + expectedSD,
            },
            {
              label: "Out for Delivery",
              isCompleted: false,
              subtitle: "Expected by " + expectedDD,
            },
            {
              label: "Delivered",
              isCompleted: false,
              subtitle: "Expected by " + expectedDD,
            },
          ];
          break;
        case "Shipped":
          activeStepRef.current = 1;
          steps = [
            {
              label: "Confirmed",
              isCompleted: true,
              subtitle: label_createdAt,
            },
            {
              label: "Shipped",
              isCompleted: true,
              subtitle:
                "On " +
                order.shippedAt.substring(0, 10) +
                " at " +
                order.shippedAt.substring(15, 24),
            },
            {
              label: "Out for Delivery",
              isCompleted: false,
              subtitle: "Expected by " + expectedDD,
            },
            {
              label: "Delivered",
              isCompleted: false,
              subtitle: "Expected by " + expectedDD,
            },
          ];
          break;
        case "Out for delivery":
          activeStepRef.current = 2;
          steps = [
            {
              label: "Confirmed",
              isCompleted: true,
              subtitle: label_createdAt,
            },
            {
              label: "Shipped",
              isCompleted: true,
              subtitle:
                order.shippedAt.substring(0, 10) +
                order.shippedAt.substring(15, 24),
            },
            {
              label: "Out for Delivery",
              isCompleted: true,
              subtitle:
                order.ofdAt !== undefined
                  ? order.ofdAt.substring(0, 10) + order.ofdAt.substring(15, 24)
                  : "Expected within 3 days",
            },
            {
              label: "Delivered",
              isCompleted: false,
              subtitle: "Expected by today",
            },
          ];
          break;
        case "Delivered":
          activeStepRef.current = 4;
          steps = [
            {
              label: "Confirmed",
              isCompleted: true,
              subtitle: label_createdAt,
            },
            {
              label: "Shipped",
              isCompleted: true,
              subtitle:
                order.shippedAt !== undefined
                  ? order.shippedAt.substring(0, 10) +
                    order.shippedAt.substring(15, 24)
                  : order.deliveredAt.substring(0, 10) +
                    order.deliveredAt.substring(15, 24),
            },
            {
              label: "Out for Delivery",
              isCompleted: true,
              subtitle:
                order.ofdAt !== undefined
                  ? order.ofdAt.substring(0, 10) + order.ofdAt.substring(15, 24)
                  : order.deliveredAt.substring(0, 10) +
                    order.deliveredAt.substring(15, 24),
            },
            {
              label: "Delivered",
              isCompleted: true,
              subtitle:
                order.deliveredAt.substring(0, 10) +
                order.deliveredAt.substring(15, 24),
            },
          ];
          break;
        case "Cancelled by buyer":
          activeStepRef.current = 2;
          steps = [
            {
              label: "Confirmed",
              isCompleted: true,
              subtitle: label_createdAt,
            },
            {
              label: "Cancelled",
              isCompleted: true,
              subtitle:
                order.cancelledAt.substring(0, 10) +
                order.cancelledAt.substring(15, 24),
            },
          ];
          break;
        case "Cancelled by seller":
          activeStepRef.current = 2;
          steps = [
            {
              label: "Confirmed",
              isCompleted: true,
              subtitle: label_createdAt,
            },
            {
              label: "Cancelled by seller",
              isCompleted: true,
              subtitle:
                order.cancelledAt.substring(0, 10) +
                order.cancelledAt.substring(15, 24),
            },
          ];
          break;
      }
      //console.log("steps:", steps);
      setOrderSteps(steps);
      const totalAmount = order.items.reduce(function (
        accumulator,
        currentValue
      ) {
        return accumulator + currentValue.listPrice * currentValue.quantity;
      },
      0);
      setItemsAmount(totalAmount);
      const shipAmount = order.shippingCost ? order.shippingCost : 0;
      setShippingAmount(shipAmount);
      const couponDiscount = order.couponApplied
        ? order.couponApplied.amount
        : 0;
      setCouponDis(couponDiscount);
    }
  }, []);

  useEffect(() => {
    if (userProfileContext.state !== null) {
      //console.log("user:", userProfileContext.state);
      setUser({ ...userProfileContext.state });
    } else {
      setUser(null);
    }
  }, [userProfileContext.state]);
  useEffect(() => {
    if (cancelOrder) {
      handleCancelOrder();
    }
  }, [cancelOrder]);
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const handleAgreeDialog = () => {
    setCancelOrder(true);
    setOpenDialog(false);
  };
  const handleCancelOrder = () => {
    //console.log("Cancel order:", order.id);
    // Cancellation Start
    const dataForOrderTable = {
      payload: {
        status: "Cancelled by buyer",
        cancelledAt: Date().toString(),
      },
      id: order.id,
    };
    ordersAPI.setOrder(dataForOrderTable).then((resOrder) => {
      // console.log("RES ORDER:", resOrder);
      // console.log("user.orders:", user.orders);
      const prevOrders = user.orders !== undefined ? user.orders : [];
      // remove this order
      const prevOrdersSpliced = prevOrders.filter((item, index) => {
        return item.id !== order.id;
      });
      // console.log("prevOrdersSpliced: ", prevOrdersSpliced);
      const dataForUsersTable = {
        id: user.id,
        payload: {
          orders: [
            {
              amount: order.amount,
              createdAt: order.createdAt,
              id: order.id,
              ...dataForOrderTable.payload,
            },
            ...prevOrdersSpliced,
          ],
        },
      };
      // console.log("dataForUsersTable:", dataForUsersTable);
      userDataApi.addUserData(dataForUsersTable).then((res) => {
        // console.log("RES from user data update:", res);
        if (res.success) {
          userProfileContext.setState((prev) => ({
            ...prev,
            orders: [...dataForUsersTable.payload.orders],
          }));
        }
        setTimeout(() => {
          setCancelOrder(false);
          navigate("/userOrders");
        }, 1000);
      });
    });
  };
  const retryPayment = () => {
    //setLoading(true);
    const options = {
      // key: process.env.REACT_APP_RAZORPAY_KEY_LIVE, // Enter the Key ID generated from the Dashboard
      key:
        process.env.REACT_APP_PAYMENT_METHOD === "TEST"
          ? process.env.REACT_APP_RAZORPAY_KEY_TEST
          : process.env.REACT_APP_RAZORPAY_KEY_LIVE,
      amount: order.amount * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: "INR",
      name: "Apeksha Lifecare Products", //your business name
      description: "For Order " + order.id,
      // image: "https://myecommerce-a647b.web.app/favicon.ico",
      order_id: order.id, //This is the `id` obtained in the response of Step 1
      handler: (response) => {
        //console.log("Response:", response);
        handlePaymentUpdate({ success: true, response });
        //setOrderData(null);
      },
      prefill: {
        //We recommend using the prefill parameter to auto-fill customer's contact information, especially their phone number
        name: user.displayName, //your customer's name
        email: user.email,
        contact: user.mobile, //Provide the customer's phone number for better conversion rates
      },
      notes: {
        address: "Apeksha Lifecare Products, Haridwar, UK, INDIA",
      },
      theme: {
        color: process.env.REACT_APP_BG_COLOR,
      },
      modal: {
        ondismiss: function () {
          // This function is called when the payment modal is closed
          console.log("Payment modal closed by the user.");
          navigate("/userOrders");
        },
      },
    };
    const rzp1 = new Razorpay(options);

    rzp1.on("payment.failed", function (response) {
      //console.log("payment.failed:", response);
      setLoading(false);
      handlePaymentUpdate({ success: false, response });
      // alert(response.error.code);
      // alert(response.error.description);
      // alert(response.error.source);
      // alert(response.error.step);
      // alert(response.error.reason);
      // alert(response.error.metadata.order_id);
      // alert(response.error.metadata.payment_id);
    });
    rzp1.open();
  };
  const handlePaymentUpdate = (props) => {
    // console.log(
    //   "handlePaymentUpdate props:",
    //   props,
    //   "__currentOrder:",
    //   order,
    //   "__user:",
    //   user
    // );
    setLoading(true);
    const { success, response } = props;
    if (success) {
      //sendMail();
      // Payment Completed
      const payment_id = response.razorpay_payment_id;
      const dataForOrderTable = {
        payload: {
          payment: {
            method: "online",
            status: "completed",
            payment_id: payment_id,
            attempted_at: Date().toString(),
          },
          status: "Placed",
        },
        id: order.id,
      };
      ordersAPI.setOrder(dataForOrderTable).then((resOrder) => {
        // console.log("RES ORDER:", resOrder);
        const prevOrders = user.orders !== undefined ? user.orders : [];
        //console.log("prevOrders:", prevOrders);
        //update object in prevOrders where order id = currentOrder.id
        const updatedOrders = prevOrders.map((order_) => {
          if (order_.id === order.id) {
            return { ...order_, ...dataForOrderTable.payload };
          }
          return order_;
        });
        const dataForUsersTable = {
          id: user.id,
          payload: {
            orders: [...updatedOrders],
          },
        };
        //console.log("dataForUsersTable with updated order:", dataForUsersTable);
        userDataApi.addUserData(dataForUsersTable).then((res) => {
          // console.log("RES from user data update:", res);
          if (res.success) {
            userProfileContext.setState((prev) => ({
              ...prev,
              cartItems: [],
              couponApplied: null,
              orders: dataForUsersTable.payload.orders,
            }));
            // navigate to order placed page
            navigate("/orderPlaced");
          }
        });
      });
    } else {
      setLoading(false);
      // Payment failed
      console.log("Payment Failed: " + response.error.description);
    }
  };
  const isPaymentProcessing = (order) => {
    const orderAt = new Date(order.createdAt);
    const nowDate = new Date();

    // date diffrence in minutes
    const diff = (nowDate - orderAt) / 60000;
    // console.log(
    //   "order at:" + orderAt + " nowDate:" + nowDate + "\n diff:" + diff
    // );
    if (diff < 5) {
      return true;
    }
    return false;
  };
  return (
    <Container maxWidth="md" sx={{ marginTop: "2rem" }}>
      <Grid container spacing={2} sx={{ pb: "1rem" }}>
        <Grid item xs={2} sm={1} textAlign="left" sx={{ p: "0 !important" }}>
          <IconButton aria-label="back" onClick={() => navigate(-1)}>
            <ArrowBackIcon fontSize="large" />
          </IconButton>
        </Grid>
        <Grid item xs={10} sm={11} sx={{ p: "6px 0 !important" }}>
          <Typography variant="body1" color="GrayText">
            Order ID - {order.id}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ p: "6px 0 !important" }}
          display={{ xs: "block", sm: "none", md: "none", lg: "none" }}
        >
          <Divider sx={{ width: "100vw" }} />
        </Grid>

        <Grid item xs={12} sx={{ p: " 0 0 1rem 1rem !important" }}>
          <Paper sx={{ p: "1rem" }}>
            <VerticalLinearStepper
              steps={orderSteps}
              activeStep={activeStepRef.current}
              editable={false}
            />
          </Paper>
        </Grid>
        {order.status === "CREATED" && (
          <Grid
            container
            spacing={2}
            justifyContent={"space-around"}
            sx={{ p: "1rem", paddingRight: "0" }}
          >
            <Grid item xs={6}>
              <CustomButton
                variant="outlined"
                onClick={() => setOpenDialog(true)}
                size="small"
                fullWidth={true}
                disabled={isPaymentProcessing(order)}
              >
                Cancel Order
              </CustomButton>
            </Grid>
            <Grid item xs={6}>
              <CustomButton
                variant="contained"
                onClick={retryPayment}
                size="small"
                fullWidth={true}
                disabled={isPaymentProcessing(order)}
              >
                Pay now
              </CustomButton>
            </Grid>
          </Grid>
        )}
        <Grid item xs={12} sx={{ p: "6px 1rem !important" }}>
          <Typography variant="body2" component="p" color="GrayText">
            Total Items ({order.items.length})
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ p: "6px !important" }}>
          <Divider />
        </Grid>
        <Grid item xs={12} sx={{ pt: "0 !important" }}>
          <ProductsContainer
            heading=""
            showInRow={true}
            addToCartEnable={false}
            showCarousel={false}
            showLikeButton={false}
            productsArray={order.items}
            useMobileSize={true}
            showRating={false}
            showQuantity={true}
          />
        </Grid>
        <Grid item xs={12} sx={{ p: "1rem 0 1rem 1rem !important" }}>
          {/* order amount details */}
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              Amount details (&#8377;{order.amount})
            </AccordionSummary>
            <AccordionDetails sx={{ p: 0 }}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: "100%" }} aria-label="simple table">
                  <TableBody>
                    <TableRow
                      key="items_amount"
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        Item's amount
                      </TableCell>
                      <TableCell align="right">&#8377;{itemsAmount}</TableCell>
                    </TableRow>
                    <TableRow
                      key="shipping_cost"
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        Shipping cost
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{
                          fontWeight: "500",
                          color: shippingAmount > 0 ? "darkred" : "black",
                        }}
                      >
                        &#8377;{shippingAmount}
                      </TableCell>
                    </TableRow>
                    {order.codCharges && order.codCharges > 0 && (
                      <TableRow
                        key="cod_charges"
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          COD Charges
                        </TableCell>
                        <TableCell align="right">
                          &#8377;{order.codCharges}
                        </TableCell>
                      </TableRow>
                    )}
                    {couponDis !== 0 && (
                      <TableRow
                        key="coupon_discount"
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{ fontWeight: "500", color: "darkgreen" }}
                        >
                          Coupon applied ({order.couponApplied.couponCode})
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{ fontWeight: "500", color: "darkgreen" }}
                        >
                          - &#8377;{couponDis}
                        </TableCell>
                      </TableRow>
                    )}
                    <TableRow
                      key="final_amount"
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        Final amount
                      </TableCell>
                      <TableCell align="right">
                        &#8377;
                        {itemsAmount +
                          (order.codCharges ? order.codCharges : 0) +
                          shippingAmount -
                          couponDis}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              {/* table for order amount details */}
            </AccordionDetails>
          </Accordion>
          {/* <Typography variant="body1" component="p">
              Order amount : &#8377;{order.amount}
            </Typography> */}
        </Grid>
        <Grid item xs={12} sx={{ p: "6px 1rem !important" }}>
          <Typography variant="body2" component="p" color="GrayText">
            Shipping Address
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ p: "6px !important" }}>
          <Divider />
        </Grid>
        <Grid item xs={12} sx={{ p: "6px 1rem !important" }}>
          <Typography variant="body1" component="p">
            {order.address.name}
          </Typography>
          <Typography variant="body2" component="p">
            {order.address.address}
          </Typography>
          <Typography variant="body2" component="p">
            {order.address.city}, {order.address.state} -{" "}
            {order.address.pincode}
          </Typography>
          <Typography variant="body2" component="p">
            {order.address.mobile}{" "}
            {order.address.alternateMobile !== ""
              ? `, ${order.address.alternateMobile}`
              : ""}
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ p: "6px !important" }}>
          <Divider />
        </Grid>
        {order.status === "Placed" && (
          <Grid item xs={6}>
            <CustomButton
              variant="contained"
              onClick={() => setOpenDialog(true)}
              size="small"
              fullWidth={true}
            >
              Cancel Order
            </CustomButton>
          </Grid>
        )}
        <Grid item xs={order.status === "Placed" ? 6 : 12}>
          <Link to="/refund-policy">
            <CustomButton variant="contained" size="small" fullWidth={true}>
              Need Help?
            </CustomButton>
          </Link>
        </Grid>
      </Grid>
      <AlertDialogSlide
        handleOnAgree={handleAgreeDialog}
        handleOnClose={handleCloseDialog}
        isOpen={openDialog}
        title="Cancel Order"
        description="Sure to cancel this order?"
        agreeButtonText="Confirm"
        cancelButtonText="No"
      />
      {(cancelOrder || loading) && <Loader />}
    </Container>
  );
};

export default OrderDetailPage;
